import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { Grid, Box } from '@material-ui/core'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  stats: {
    display: 'flex',
  },
}))

const StatsModule = (props) => {
  const classes = useStyles(props)
  const numberOfColumns = 12 / props.blok.stats.length
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          {!!props.blok.stats.length && (
            <Box className={classes.stats}>
              <Grid container direction="row" spacing={4}>
                {!!props.blok.stats &&
                  !!props.blok.stats.length &&
                  props.blok.stats.map((blok) => (
                    <Grid item key={blok._uid} xs={12} sm={numberOfColumns}>
                      {renderBlok(blok)}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          )}
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default StatsModule
